import { Button, Card } from "@material-tailwind/react";
import { CaseSelection } from "../../pages/CreateBuild";
import { Build } from "../../types";
import { useEffect, useState } from "react";
import axios from "axios";
import { CaseVariant } from "./Case";

type ReviewProps = {
    build: Build;
    selectedCase: CaseSelection;
    onPurchase: (ram: number, storage: number, price: number) => void;
}

const Review: React.FC<ReviewProps> = ({ build, selectedCase, onPurchase }) => {
    const [selectedImage, setSelectedImage] = useState<string>("");
    const [variant, setVariant] = useState<CaseVariant>();

    const [ram, setRAM] = useState<number>(build.ram.ram);
    const [storage, setStorage] = useState<number>(1);

    const [additionalPrice, setAdditionalPrice] = useState<number>(0);
    const [priceSteps, setPriceSteps] = useState<{ ram: Array<{ r: number, price: number }>, storage: Array<{ stor: number, price: number }> } | null>(null);

    useEffect(() => {
        setVariant(selectedCase.variant);
        setSelectedImage(selectedCase.variant.images[0]);
    }, [selectedCase]);

    useEffect(() => {
        const apiURL = process.env.REACT_APP_DEV ? "http://localhost:8000" : "https://api.adriftcomputers.com";

        const fetchPriceSteps = async () => {
            try {
                const response = await axios.post(`${apiURL}/priceSteps`, {
                    buildRAM: build.ram.ram,
                    buildStorage: build.storage.storage,
                });
                console.log(response);
                setPriceSteps(response.data);
            } catch (err) {
                console.error('Error fetching price steps:', err);
            }
        };

        fetchPriceSteps();
    }, [build.ram.ram, build.storage.storage]);

    useEffect(() => {
        if (priceSteps) {
            const ramPriceStep = priceSteps.ram.find(item => item.r === ram);
            const storagePriceStep = priceSteps.storage.find(item => item.stor === storage*1000);

            const ramPrice = ramPriceStep ? ramPriceStep.price : 0;
            const storagePrice = storagePriceStep ? storagePriceStep.price : 0;

            setAdditionalPrice(ramPrice + storagePrice);
        }
    }, [ram, storage, priceSteps]);

    return (
        <>
            {variant &&
                <div className="mt-4">
                    <Card className={`text-[#212121] mb-16 h-full pb-4 w-full relative`}>
                        <div className="xl:flex xl:flex-row">
                            <div className="pt-4 px-4 flex flex-col gap-4">
                                <div className="text-[32px] xl:text-[40px] font-bold">Your Build</div>
                                <div className="text-[24px] xl:text-[30px] font-semibold">CPU: <span className="font-normal">{build.cpu.name}</span></div>
                                <div className="text-[24px] xl:text-[30px] font-semibold">GPU: <span className="font-normal">{build.gpu.name}</span></div>
                                <div className="text-[24px] xl:text-[30px] font-semibold">
                                    RAM:
                                    <span>
                                        <Button variant="outlined" className={`ml-2 text-[14px] ${ram === 16 ? '' : 'text-[#CFD8DC] border-[#CFD8DC]'} ${build.ram.ram > 16 ? 'hidden' : ''}`} onClick={() => { setRAM(16) }}>16GB</Button>
                                    </span>
                                    <span>
                                        <Button variant="outlined" className={`ml-2 text-[14px] ${ram === 32 ? '' : 'text-[#CFD8DC] border-[#CFD8DC]'} ${build.ram.ram > 32 ? 'hidden' : ''}`} onClick={() => { setRAM(32) }}>32GB</Button>
                                    </span>
                                    <span>
                                        <Button variant="outlined" className={`ml-2 text-[14px] ${ram === 64 ? '' : 'text-[#CFD8DC] border-[#CFD8DC]'}`} onClick={() => { setRAM(64) }}>64GB</Button>
                                    </span>
                                </div>
                                <div className="text-[24px] xl:text-[30px] font-semibold">
                                    Storage:
                                    <span>
                                        <Button variant="outlined" className={`ml-2 text-[14px] ${storage === 1 ? '' : 'text-[#CFD8DC] border-[#CFD8DC]'}`} onClick={() => { setStorage(1) }}>1TB</Button>
                                    </span>
                                    <span>
                                        <Button variant="outlined" className={`ml-2 text-[14px] ${storage === 2 ? '' : 'text-[#CFD8DC] border-[#CFD8DC]'}`} onClick={() => { setStorage(2) }}>2TB</Button>
                                    </span>
                                    <span>
                                        <Button variant="outlined" className={`ml-2 text-[14px] ${storage === 4 ? '' : 'text-[#CFD8DC] border-[#CFD8DC]'}`} onClick={() => { setStorage(4) }}>4TB</Button>
                                    </span>
                                </div>

                                <div className="">
                                    <div className="text-[32px] xl:text-[40px] font-bold mt-8">Price: <span className="font-normal">${build.price + additionalPrice}.00</span></div>
                                    <Button size="lg" className="px-4 mt-4 flex flex-row justify-center w-full" onClick={() => onPurchase(ram, storage, (build.price + additionalPrice))}>
                                        Continue to Checkout
                                    </Button>
                                </div>

                            </div>
                            <div className="flex flex-col xl:items-center xl:flex-row-reverse ml-auto">
                                <div className="flex justify-center xl:mt-4 xl:pl-4 max-h-[400px] min-w-[200px] xl:min-w-[400px]">
                                    <img src={selectedImage} className="rounded-lg max-h-[400px]" alt="Selected Case" />
                                </div>
                                {/*Image Scroller*/}
                                <Card className="w-full mt-4 max-h-[100px] xl:max-h-none xl:w-[100px] xl:overflow-hidden " style={{ height: 450 }}>
                                    <div className={`flex flex-row xl:flex-col gap-[10px] overflow-y-auto xl:overflow-x-auto no-scrollbar`}>
                                        {variant.images.map((img, index) => (
                                            <img
                                                key={index}
                                                src={img}
                                                alt=""
                                                height={100}
                                                className={`rounded-lg cursor-pointer max-w-[100px] max-h-[100px]`}
                                                onClick={() => setSelectedImage(img)}
                                            />
                                        ))}
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </Card>
                </div>
            }
        </>
    )
}

export default Review;
